import { useEffect, useCallback, useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import shallowEqual from '@utils/shallowCompare';

const Field = ({ registrar, name, rules, render, renderError }) => {
  const { fieldRegister, fieldDeregister, setData, initalData, data, errors } =
    registrar || {};
  const value = data[name];
  const { error, ...rest } = errors[name] || {};

  const [currentValue, setCurrentValue] = useState(value);
  const debouncedSetData = useRef();

  useEffect(() => {
    setData(name, get(initalData, name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initalData, name]);

  useEffect(() => {
    debouncedSetData.current = debounce(
      (field, updatedValue) => setData(field, updatedValue),
      250
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    fieldRegister(name, rules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRegister, name]);

  useEffect(() => {
    return () => fieldDeregister(name);
  }, [fieldDeregister, name]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onChange = useCallback(
    (updatedValue) => {
      setCurrentValue(updatedValue);
      debouncedSetData.current(name, updatedValue);
    },
    [name]
  );

  return (
    <>
      {render({
        onChange,
        value: currentValue,
        data,
        hasError: error,
        updateFormData: setData
      })}
      {renderError && error && renderError(rest)}
    </>
  );
};

const fieldPropsAreEqual = (prevFieldProps, nextFieldProps) => {
  if (prevFieldProps.memoize || nextFieldProps.memoize) {
    const isValuesEqual =
      prevFieldProps.registrar.data[prevFieldProps.name] ===
      nextFieldProps.registrar.data[nextFieldProps.name];

    const isInitalDataEqual =
      prevFieldProps.registrar.initalData ===
      nextFieldProps.registrar.initalData;

    const isNameEqual = prevFieldProps.name === nextFieldProps.name;

    return isValuesEqual && isInitalDataEqual && isNameEqual;
  }

  return shallowEqual(prevFieldProps, nextFieldProps);
};

const MemoizedField = memo(Field, fieldPropsAreEqual);

export default MemoizedField;

Field.propTypes = {
  registrar: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(PropTypes.func.isRequired),
  render: PropTypes.func.isRequired,
  renderError: PropTypes.func
};

Field.defaultProps = {
  rules: [],
  renderError: null
};
