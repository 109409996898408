/* eslint-disable import/prefer-default-export */
import isEmptyUtil from '@utils/isEmpty';
import validateEmail from '@utils/validators';

export const isRequired = (errorMessage) => (value) => {
  if (isEmptyUtil(value)) {
    return {
      error: true,
      message: errorMessage
    };
  }

  return {};
};

export const isLengthMatchError = (errorMessage, length) => (value) => {
  if (value.toString().length !== length) {
    return {
      error: true,
      message: errorMessage
    };
  }

  return {};
};

export const isValidEmail = (errorMessage) => (value) => {
  if (!validateEmail(value)) {
    return {
      error: true,
      message: errorMessage
    };
  }

  return {};
};

export const isFutureDate = (errorMessage) => (value) => {
  if (value > new Date()) {
    return {
      error: true,
      message: errorMessage
    };
  }

  return {};
};
