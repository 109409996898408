/* eslint-disable no-useless-escape */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@base/TextField/TextField';

const isNegative = (value) => value === '-';
const isEmpty = (value) => !value.length;
const isDecimal = (value) => value === '.';
const isNullOrUndefined = (value) => value === null || value === undefined;
const keys = {
  backspace: 'Backspace',
  minus: '-',
  dot: '.'
};
const avoidKeyMap = {
  [keys.minus]: true,
  [keys.dot]: true
};
let lastObservedKey = null;
const getValidators = (allowNegative = false, allowFloat = false) => {
  // For float and negative
  let checkFor = {
    validSymbols: /(\-|\.|\d)+/,
    strictAllowed: /^-?((0|([0-9]\d{0,}))(\.\d{0,})?)?$/
  };
  if (!allowNegative && !allowFloat) {
    checkFor = {
      validSymbols: null,
      strictAllowed: /^([0-9]*)$/
    };
  }
  if (!allowNegative && allowFloat) {
    checkFor = {
      validSymbols: null,
      strictAllowed: /^[0-9]+([\.][0-9]*)?$/
    };
  }
  return {
    validate: (value) =>
      (checkFor.validSymbols ? value.match(checkFor.validSymbols) : true) &&
      value.match(checkFor.strictAllowed)
  };
};
const NumberField = (props) => {
  const {
    lowerLimit,
    uppperLimit,
    allowNegative,
    allowFloat,
    value,
    onChange,
    inputProps,
    ...rest
  } = props;
  // avoid null or undefined
  const [currentValue, setCurrentValue] = useState(() =>
    isNullOrUndefined(value) ? '' : value
  );
  useEffect(() => {
    const stringifiedValue = isNullOrUndefined(value) ? '' : `${value}`;
    if (currentValue !== stringifiedValue) {
      setCurrentValue(stringifiedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const validator = getValidators(allowNegative, allowFloat);
  const checkForBounds = (num) => {
    if (num === null) {
      return true;
    }
    const tempLowerLimit = isNullOrUndefined(lowerLimit)
      ? Number.MIN_SAFE_INTEGER
      : Number(lowerLimit);
    const tempUpperLimit = isNullOrUndefined(uppperLimit)
      ? Number.MAX_SAFE_INTEGER
      : Number(uppperLimit);
    return tempLowerLimit <= num && num <= tempUpperLimit;
  };
  const handleOnKeyDown = (e) => {
    lastObservedKey = e.key;
  };
  const handleOnChange = (e) => {
    const {
      target: { value: targetValue }
    } = e;
    if (keys.backspace === lastObservedKey) {
      const lastChar = targetValue[targetValue.length - 1];
      if (isDecimal(lastChar) || isNegative(lastChar)) {
        const temp = (targetValue || '').slice(0, -1);
        const parsedTemp = Number.isNaN(Number(temp)) ? null : Number(temp);
        const valueToPass = temp?.length ? parsedTemp : null;
        if (checkForBounds(valueToPass)) {
          setCurrentValue(temp);
          onChange(e, valueToPass);
        }
        return;
      }
    }
    if (avoidKeyMap[lastObservedKey]) {
      if (validator.validate(targetValue)) {
        setCurrentValue(targetValue);
        const lastChar = targetValue[targetValue.length - 1];
        if (!avoidKeyMap[lastChar]) {
          const valueToPass = Number(targetValue);
          if (checkForBounds(valueToPass)) {
            onChange(e, valueToPass);
          }
        }
      }
      return;
    }
    if (isEmpty(targetValue)) {
      setCurrentValue('');
      onChange(e, null);
      return;
    }
    if (validator.validate(targetValue)) {
      const valueToPass = Number(targetValue);
      if (checkForBounds(valueToPass)) {
        setCurrentValue(targetValue);
        onChange(e, Number(targetValue));
      }
    }
  };
  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      value={currentValue}
      type="text"
      onKeyDown={handleOnKeyDown}
      onChange={handleOnChange}
      inputProps={{
        maxLength: 16,
        ...inputProps
      }}
    />
  );
};
NumberField.propTypes = {
  ...TextField.propTypes,
  uppperLimit: PropTypes.number,
  lowerLimit: PropTypes.number,
  allowNegative: PropTypes.bool,
  allowFloat: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.objectOf(PropTypes.any)
};
NumberField.defaultProps = {
  ...TextField.defaultProps,
  uppperLimit: Number.MAX_SAFE_INTEGER,
  lowerLimit: Number.MIN_SAFE_INTEGER,
  allowNegative: false,
  allowFloat: false,
  value: '',
  inputProps: {}
};
export default NumberField;
