import { useState, useRef, useCallback } from 'react';
import merge from 'lodash/merge';
import setWith from 'lodash/setWith';
import get from 'lodash/get';

export const CRITERIA_MODE = {
  ALL: 'all',
  FIRST_ERROR: 'firstError'
};

const defaultFormState = {
  isSubmitting: false
};

const validateField = (name, value = null, rules = []) => {
  for (let i = 0; i < rules.length; i += 1) {
    const validatedResult = rules[i](value, name) || {};

    if (validatedResult.error) {
      return validatedResult;
    }
  }

  return {};
};

const useForm = ({
  data,
  validate: recordLevelValidator,
  criteriaMode = CRITERIA_MODE.FIRST_ERROR
}) => {
  const rulesRef = useRef({});
  const errorRef = useRef({});
  const [formState, setFormState] = useState(defaultFormState);
  const [formData, setFormData] = useState({});

  const updateFormError = useCallback((name, error) => {
    errorRef.current[name] = error;
  }, []);

  const resetFormError = useCallback(() => {
    errorRef.current = {};
  }, []);

  const resetForm = useCallback(() => {
    rulesRef.current = {};
    errorRef.current = {};

    setFormState(defaultFormState);
    setFormData({});
  }, []);

  const updateFormState = useCallback((newFormState) => {
    setFormState((previousFormState) => ({
      ...previousFormState,
      ...newFormState
    }));
  }, []);

  const fieldRegister = useCallback((name, rules) => {
    if (rulesRef.current[name]) {
      return;
    }

    rulesRef.current[name] = rules;
  }, []);

  const fieldDeregister = useCallback((name) => {
    delete rulesRef.current[name];
    delete errorRef.current[name];
  }, []);

  const updateFormData = useCallback((name, value) => {
    setFormData((previousFormData) => ({
      ...previousFormData,
      [name]: value
    }));
  }, []);

  const generateDataObject = () => {
    const obj = {};

    Object.keys(formData).forEach((key) => {
      setWith(obj, key, formData[key], Object);
    });

    return merge({}, data, obj);
  };

  const generateErrorObject = () => {
    const obj = {};

    Object.keys(errorRef.current).forEach((key) => {
      if (Object.keys(errorRef.current[key]).length) {
        obj[key] = errorRef.current[key];
      }
    });

    return obj;
  };

  const validateFields = (dataObj) => {
    let hasErrors = false;
    const isFirstErrorMode = criteriaMode === CRITERIA_MODE.FIRST_ERROR;
    const fieldsToValidate = Object.keys(rulesRef.current || {});

    for (let i = 0; i < fieldsToValidate.length; i += 1) {
      const fieldName = fieldsToValidate[i];
      const fieldRules = rulesRef.current[fieldName];
      const fieldValue = get(dataObj, fieldName);

      const fieldError = validateField(fieldName, fieldValue, fieldRules);

      if (fieldError?.error) {
        hasErrors = true;

        if (isFirstErrorMode) {
          resetFormError();
          updateFormError(fieldName, fieldError);

          break;
        } else {
          updateFormError(fieldName, fieldError);
        }
      } else {
        updateFormError(fieldName, {});
      }
    }

    return { hasErrors };
  };

  const handleSubmit = (onValid, onInvalid) => async (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const dataObj = generateDataObject();

    try {
      const { hasErrors } = validateFields(dataObj);

      if (hasErrors) {
        if (onInvalid) {
          const errorObj = generateErrorObject();

          return await onInvalid(errorObj);
        }
      } else {
        resetFormError();

        if (recordLevelValidator) {
          const recordError = await recordLevelValidator(dataObj);

          if (recordError?.error) {
            return await onInvalid(recordError);
          }
        }

        if (onValid) {
          updateFormState({ isSubmitting: true });
          return await onValid(dataObj);
        }
      }
    } finally {
      updateFormState({ isSubmitting: false });
    }
  };

  return {
    registrar: {
      fieldRegister,
      fieldDeregister,
      initalData: data,
      setData: updateFormData,
      data: formData,
      errors: errorRef.current
    },
    resetForm,
    onFormSubmit: handleSubmit,
    isSubmitting: formState.isSubmitting,
    isDirty: formState.isDirty
  };
};

export default useForm;
