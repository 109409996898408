// eslint-disable-next-line import/prefer-default-export
export const OFFLINE_CHECKOUT_STARTED = 'Offline - Checkout Started';
export const OFFLINE_TAKE_TOUR_CLICKED = 'Offline - Take a Tour';
export const OFFLINE_DOWNLOAD_BROCHURE_CLICKED = 'Offline - Download Brochure';
export const CHECKOUT_CITY_SELECTED = 'Checkout - City Selected';
export const CHECKOUT_CITY_SUGGESTED = 'Checkout - City Suggested';
export const FORM_ENQUIRY_FORM_VIEWED = 'Form - Enquiry Form Viewed';
export const FORM_ENQUIRY_FORM_CONTINUE = 'Form - Enquiry Form Continue';
export const FORM_ENQUIRY_FORM_SUBMITTED = 'Form - Enquiry Form Submitted';
export const OFFLINE_CENTRE_PAGE_LANDED = 'Offline Centres - Page Landed';
export const OFFLINE_CENTRE_KNOW_MORE_CLICKED = 'Offline Centres - Know More';
export const OFFLINE_GOAL_UID = 'JVITK';
export const OFFLINE_VIDEO_TOUR_PLAYED = 'Offline - Video Tour Played';
export const KNOW_MORE_FLOW_TYPE_WEB_SUBSCRIPTION = 'Web Subscription';
export const KNOW_MORE_FLOW_TYPE_UN_HOME_PAGE = 'Unacademy homepage';
export const KNOW_MORE_FLOW_TYPE_WEB_HOMEPAGE = 'Web Homepage';
export const OFFLINE_VIDEO_TOUR_PLAYED_TOUR_SOURCE_OFFLINE_HOME_PAGE =
  'Web Homepage';
export const KNOW_MORE_FLOW_TYPE_SUBSCRIPTION_PAGE = 'Subscription Page';

export const OFFLINE_VIDEO_TOUR_PLAYED_TOUR_SOURCE_CENTRE_GOAL_PAGE =
  'Centre Goal Page';

export const OFFLINE_CHECKOUT_FLOW_TYPE_CITY = 'select_a_city';
export const OFFLINE_CHECKOUT_FLOW_TYPE_CITY_CAB_TOP = 'CAB_top';
export const OFFLINE_CHECKOUT_FLOW_TYPE_CITY_CAB_MIDDLE = 'CAB_mid';
export const OFFLINE_CHECKOUT_FLOW_TYPE_CITY_CAB_BOTTOM = 'CAB_bottom';
export const OFFLINE_APPLY_SCHOLARSHIP_CLICKED =
  'Offline - Apply Scholarship Clicked';

export const OFFLINE_VIEW_SCHOLARSHIP_DETAILS =
  'Offline - View Scholarship Details';

export const OFFLINE_VIDEO_TOUR_PLAYED_TOUR_SOURCE_SUBSCRIPTION =
  'Web Subscription';

export const OFFLINE_SUBSCRIPTION_FEEDBACK_PAGE_LANDED =
  'Offline Subscription Feedback Page Landed';

export const OFFLINE_SUBSCRIPTION_RATING_SUBMITTED =
  'Offline Subscription Rating Submitted';

export const OFFLINE_SUBSCRIPTION_FEEDBACK_SUBMITTED =
  'Offline Subscription Feedback Submitted';

export const DEFAULT_COUNTRY = {
  code: 'IN',
  name: 'India',
  flagIconUrl: 'https://static.uacdn.net/thumbnail/country-flag-icons/in.png',
  phoneCode: '91'
};

export const classes = [
  { id: '6', label: 'Class 5', value: 'Class 5' },
  { id: '7', label: 'Class 6', value: 'Class 6' },
  { id: '8', label: 'Class 7', value: 'Class 7' },
  { id: '1', label: 'Class 8', value: 'Class 8' },
  { id: '2', label: 'Class 9', value: 'Class 9' },
  { id: '3', label: 'Class 10', value: 'Class 10' },
  { id: '4', label: 'Class 11', value: 'Class 11' },
  { id: '5', label: 'Class 12', value: 'Class 12' }
];

export const bloodGroups = [
  { id: '1', label: 'A+', value: 'A+' },
  { id: '2', label: 'A-', value: 'A-' },
  { id: '3', label: 'B+', value: 'B+' },
  { id: '4', label: 'B-', value: 'B-' },
  { id: '4', label: 'AB+', value: 'AB+' },
  { id: '4', label: 'AB-', value: 'AB-' },
  { id: '4', label: 'O+', value: 'O+' },
  { id: '4', label: 'O-', value: 'O-' }
];

export const candidateIdTypes = [
  { id: '1', label: 'AADHAR card', value: 'AADHAR card' },
  { id: '2', label: 'Passport', value: 'Passport' },
  { id: '3', label: 'School ID', value: 'School ID' }
];

export const parentIdTypes = [
  { id: '1', label: 'AADHAR card', value: 'AADHAR card' },
  { id: '2', label: 'Passport', value: 'Passport' },
  { id: '3', label: 'Voter ID', value: 'Voter ID' },
  { id: '4', label: 'Driving license', value: 'Driving license' },
  { id: '5', label: 'PAN card', value: 'PAN card' }
];

export const goalDetailsData = {
  Goal: {
    placeholder: 'Select your goal'
  },
  Class: {
    placeholder: 'Select your class'
  },
  Centre: {
    placeholder: 'Select your centre'
  },
  Year: {
    placeholder: 'Target Year'
  }
};

export const VALIDATIONS = {
  maxDOB: 1293753600000 // 2010-12-31'
};
