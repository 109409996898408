import { useState, memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import MenuItem from '@comp/Dropdown/MenuItem';
import KeyboardArrowDown from '@icon/KeyboardArrowDown';
import P1 from '@base/P1/P1';
import Menu from '@base/Menu/Menu';
import { Spacings, Fonts, breakpoints } from '@styles/index';

const BoldP1 = styled(P1)`
  padding: 0 ${Spacings.SPACING_2B} 0 ${Spacings.SPACING_3B};
  font-weight: ${Fonts.SEMIBOLD};
`;

const StyledSelectedMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $disabled }) => (!$disabled ? 'cursor: pointer;' : '')}
  margin-right: ${Spacings.SPACING_2B};
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin-right: 0px;
  }
`;

const StyledImg = styled.img`
  width: ${Spacings.SPACING_6B};
`;

const StyledArrowDown = styled(KeyboardArrowDown)`
  transition: transform 400ms ease-in-out;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-top: 2px;
`;

const countryOptions = (countries) => {
  if (!countries?.length) return [];
  return countries.map((c) => ({
    label: c.name,
    value: c.code,
    icon: c.flagIconUrl
  }));
};

const CountryMenu = ({ changeCountry, country, countries, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleOptionClick = (option) => {
    changeCountry(option);
    handleClose();
  };
  if (!country) return null;

  return (
    <>
      <StyledSelectedMenu onClick={handleClick}>
        <StyledImg
          src={country.flagIconUrl || country.flag_icon_url}
          alt={country.name}
        />
        <BoldP1>{`+${country.phoneCode || country.phone_code}`}</BoldP1>
        {!disabled ? <StyledArrowDown open={anchorEl} size="16px" /> : null}
      </StyledSelectedMenu>

      <Menu anchorEl={anchorEl} onClose={handleClose} disablePortal>
        {countryOptions(countries).map((option) => (
          <MenuItem
            displayCheck
            selected={country?.code === option.value}
            option={option}
            onClick={handleOptionClick}
            key={option.value}
          />
        ))}
      </Menu>
    </>
  );
};

export default memo(CountryMenu);

CountryMenu.propTypes = {
  country: PropTypes.objectOf(PropTypes.any).isRequired,
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  changeCountry: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

CountryMenu.defaultProps = {
  disabled: false
};
